import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.scss';

class CarouselContent extends Component {

    constructor() {
        super();

        this.handleResize = this.handleResize.bind(this);

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
    }

    handleResize() {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    
    componentDidUpdate(prevProps, prevState) {
        
    }
    

    render() {
        const { slides, checkedInput, show, id } = this.props;

        return (
            <Container id={id} className={`carouselContent ${show ? "appear" : ""}`} fluid>
                {
                    slides.map((_, i) => (
                        <input
                            type="radio"
                            name="carouselContent"
                            id={`carousel-${i}`}
                            key={`carousel-input-${i}`}
                            checked={i === checkedInput}
                            readOnly></input>
                    ))
                }
                <Row style={{height: (window.innerWidth < 768) ? (550*slides[checkedInput].length) : 500}}>
                    {
                        slides.map((slideGroup, i) => (
                            <div className={`slideGroup ${i === checkedInput ? "active" : ""}`} key={i}>
                                {
                                    slideGroup.map((slide, j) => (
                                        <Col xs={12} md={Math.round(12 / slideGroup.length)} key={`slide-col-${j}`}>{slide}</Col>
                                    ))
                                }
                            </div>
                        ))
                    }
                </Row>
            </Container>
        );
    }
};

CarouselContent.propTypes = {
    checkedInput: PropTypes.number.isRequired,
    slides: PropTypes.array.isRequired,
    show: PropTypes.bool,
    id: PropTypes.string,
};

export default CarouselContent;