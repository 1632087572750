import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ServerSVG } from './resources/server.svg';
import './style.scss';

class ServerGraph extends Component {
    
    componentDidUpdate(prevProps, prevState) {
        document.querySelectorAll(".ServerSVG").forEach(svg => {
            svg.style.animation = "none";
            svg.getBoundingClientRect();
            svg.style.animation = null;
        });
    }

    render() {
        const { title, skills } = this.props;

        return (
            <div className="serverGraph">
                <div className="title">
                    <p>{ title }</p>
                </div>
                <div className="references">
                    <span>Experto</span>
                    <span>Avanzado</span>
                    <span>Intermedio</span>
                    <span>Elemental</span>
                    <span>Principiante</span>
                </div>
                <div className="skills">
                    {
                        skills.map((skill, i) => (
                            <div className="skill" key={`skill-${i}`}>
                                <div className="bar">
                                    {
                                        [...Array(skill.level)].map((_, j) =>
                                            <ServerSVG className="ServerSVG" key={`server-svg-${j}`} />
                                        )
                                    }
                                </div>
                                <p>{ skill.name }</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
};

ServerGraph.propTypes = {
    title: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
};

export default ServerGraph;