import React from 'react';
import { ReactComponent as Jail } from './resources/jail.svg';
import { ReactComponent as Malware } from './resources/malware.svg';
import { ReactComponent as AntiMalware } from './resources/antiMalware.svg';
import './style.scss';

const Quarantine = props => {
    return (
        <div className="QuarantineContainer">
            <div className="QuarantineAnimation">
                <Jail className="Jail" />
                <Malware className="Malware" />
                <AntiMalware className="AntiMalware" />
            </div>
        </div>
    );
};

export default Quarantine;