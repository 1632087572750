import React, { Component } from 'react';
import './style.scss';
import Delayed from './../Delayed';

class Terminal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            children: React.Children.map( props.children, (child, i ) => 
                (
                    <Delayed waitBeforeShow={i*1000}>
                        {child}
                    </Delayed>
                )
            )
        };
    }
    
    render() {
        return (
            <div className="terminal">
                <div className="terminalToolbar">
                    <div className="toolbarButtons">
                        <button className="toolbarButton exit">&#10005;</button>
                        <button className="toolbarButton">&#9472;</button>
                        <button className="toolbarButton">&#9723;</button>
                    </div>
                    <p className="toolbarUser">root@master:~</p>
                </div>
                <div className="terminalBody">
                    {
                        this.state.children
                    }
                </div>
            </div>
        );
    }
};

export default Terminal;