import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SimpleTextCarousel from './../SimpleTextCarousel';
import { faWifi, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { faRedhat } from '@fortawesome/free-brands-svg-icons';
import './style.scss';

const carouselFooterContent = [
    { text: "Web developer", icon: faWifi },
    { text: "Security Information Enthusiast", icon: faUserLock },
    { text: "Ethical Hacker", icon: faRedhat },
]

const Footerbar = props => {
    return (
        <footer>
            <Container fluid className="containerFooter">
                <Row>
                    <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 4 }}>
                        <SimpleTextCarousel dataArray={carouselFooterContent} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Germán Ezequiel Bravo
                </Col>
                </Row>
                <Row>
                    <div className="footerSocial d-lg-none">
                        <a href="https://github.com/fearARGENTINA"><div className="icon icon-github"></div></a>
                        <a href="https://www.linkedin.com/in/german-bravo-174b957a/"><div className="icon icon-linkedin"></div></a>
                        <a href="mailto:bravogermanezequiel@gmail.com"><div className="icon icon-email"></div></a>
                    </div>
                </Row>
            </Container>
        </footer>
    );
};

export default Footerbar;