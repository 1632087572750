import React from 'react';
import Card from 'react-bootstrap/Card'
import { CardColumns, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import AppLanding from './resources/applanding.png';
import Business from './resources/business.png';
import Restaurant from './resources/restaurant.png';
import OldPortfolio from './resources/oldportfolio.png';
import './style.scss';

const Experience = props => {
    return (
        <div className="experience">
            <div className="text-center p-5">
                <h2>Works</h2>
            </div>
            <CardColumns>
                <Card>
                    <Card.Img variant="top" src={Business} alt="Business image" />
                    <Card.Body>
                        <Card.Title className="text-center">Landing page I</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>HTML5</ListGroupItem>
                        <ListGroupItem>CS33</ListGroupItem>
                        <ListGroupItem>Bootstrap</ListGroupItem>
                        <ListGroupItem>jQuery</ListGroupItem>
                        <ListGroupItem>Sass</ListGroupItem>
                        <ListGroupItem>Responsive design</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Button href="http://portfolio.germanbravo.com/landing1/">Visualizar</Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={AppLanding} alt="Applanding image" />
                    <Card.Body>
                        <Card.Title className="text-center">Landing page II</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>HTML5</ListGroupItem>
                        <ListGroupItem>CS33</ListGroupItem>
                        <ListGroupItem>Bootstrap</ListGroupItem>
                        <ListGroupItem>Sass</ListGroupItem>
                        <ListGroupItem>Responsive design</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Button href="http://portfolio.germanbravo.com/landing2">Visualizar</Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={Restaurant} alt="Restaurant image" />
                    <Card.Body>
                        <Card.Title className="text-center">Landing page III</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>HTML5</ListGroupItem>
                        <ListGroupItem>CS33</ListGroupItem>
                        <ListGroupItem>jQuery</ListGroupItem>
                        <ListGroupItem>Parallax</ListGroupItem>
                        <ListGroupItem>Responsive design</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Button href="http://portfolio.germanbravo.com/landing3/">Visualizar</Button>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={OldPortfolio} alt="Old portfolio image" />
                    <Card.Body>
                        <Card.Title className="text-center">Old portfolio</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>HTML5</ListGroupItem>
                        <ListGroupItem>CS33</ListGroupItem>
                        <ListGroupItem>jQuery</ListGroupItem>
                        <ListGroupItem>Responsive design</ListGroupItem>
                    </ListGroup>
                    <Card.Body>
                        <Button href="http://portfolio.germanbravo.com/oldportfolio/">Visualizar</Button>
                    </Card.Body>
                </Card>
            </CardColumns>
        </div>
    );
};

export default Experience;