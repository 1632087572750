import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MailSVG } from './resources/blackmail.svg';
import './style.scss';
import { LEVEL_STRINGS } from '../constants';

class MailmanGraph extends Component {
    constructor() {
        super();
        
        this.refSkills = React.createRef();
    }

    reloadAnimations() {
        if( !this.refSkills.current ) {
            return;
        }

        if (this.refSkills.current.classList.contains('active')) {
            this.refSkills.current.classList.remove('active');
        } else {
            this.refSkills.current.classList.add('active');
        }
        
        setTimeout(() => {
            this.refSkills.current.classList.add("active");
        }, 10);
    }

    componentDidMount() {
        document.querySelectorAll(".mailSVG").forEach(svg => {
            const textEl = svg.querySelector("#text");
            const level = svg.dataset["level"];
                
            if( level ) {
                textEl.textContent = LEVEL_STRINGS[parseInt(level)-1];
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this.reloadAnimations();
    }

    render() {
        const { title, skills } = this.props;

        return (
            <div className="MailmanGraph">
                <div className="title">
                    <p>{title}</p>
                </div>
                <div className="skills" id="mailman-skills" ref={this.refSkills}>
                    {
                        skills.map((skill, i) => (
                            <div className="skill" key={`mailman-skill-${i}`} >
                                <MailSVG className="mailSVG" data-level={skill.level} />
                                <p>{skill.name}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
};

MailmanGraph.propTypes = {
    title: PropTypes.string.isRequired,
    skills: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
    loadAnimation: PropTypes.bool,
};

export default MailmanGraph;