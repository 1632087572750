import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SimpleTextCarousel = props => {
    return (
        <Carousel className="simpleCarouselText" controls={false} indicators={false} interval={3000}>
            {
                props.dataArray.map( ({ text, icon }) => (
                    <Carousel.Item key={text} className="text-center">
                        <span>{text}</span><FontAwesomeIcon icon={icon} />
                    </Carousel.Item>
                ))
            }
        </Carousel>
    );
};

SimpleTextCarousel.propTypes = {
    dataArray : PropTypes.array.isRequired,
};

export default SimpleTextCarousel;