import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import TerminalText from '../TerminalText';

const TerminalPrompt = props => {
    return (
        <div className="terminalPrompt">
            <span className="promptUser">root@master:</span>
            <span className="promptLocation">~</span>
            <span className="promptDollar">$</span>
            <TerminalText type={props.type}>
                {
                    props.children
                }
            </TerminalText>
            {
                props.cursor ? <span className="promptCursor"></span> : null
            }
        </div>
    );
};

TerminalPrompt.propTypes = {
    cursor: PropTypes.bool,
    type: PropTypes.bool,
}

export default TerminalPrompt;