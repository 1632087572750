import React from 'react';
import './style.scss';
import { ReactComponent as MitMSVG } from './resources/mitm.svg';
import { ReactComponent as Mail } from './resources/mail.svg';

const MitM = props => {
    return (
        <div className="MitMContainer">
            <div className="MitMAnimation">
                <MitMSVG className="MitMSVG"/>
                <Mail className="Mail" />
                <Mail className="MailIntercepted" />
            </div>
        </div>
            
    );
};

export default MitM;