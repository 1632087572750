import React from 'react';
import CourseList from './CourseList';

const Misc = props => {
    return (
        <div>
            <div className="text-center p-5">
                <h2>Cursos de Udemy</h2>
            </div>
            <CourseList />
        </div>
    );
};

export default Misc;