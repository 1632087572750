import React, { Component } from 'react';
import { ReactComponent as Cipher } from './resources/cipher.svg';
import { ReactComponent as Lock } from './resources/lock.svg';
import PropTypes from 'prop-types';
import './style.scss';

const binariesPopping = (svg, binaries, values) => {
    const maxBinaries = binaries.length - 1;

    return setInterval(function () {
        var randomBinaryId = Math.round(Math.random() * maxBinaries);

        binaries[randomBinaryId].textContent = values[Math.round(Math.random() * 2)]
        binaries[randomBinaryId].style.animation = "bitShifting 0.2s linear forwards";
    }, 1);
}

const createPaths = (svg, intervalNumbers, callBackDone) => {
    const pathGroup = document.getElementById("PathToUncipher");
    const paths = pathGroup.getElementsByTagName("path");

    for (var i = 0; i < paths.length; i++) {
        const pathLength = paths[i].getTotalLength();

        paths[i].style.fill = "transparent";
        paths[i].style.strokeWidth = "2px";
        paths[i].style.stroke = "black";
        paths[i].style.strokeDasharray = `${pathLength} ${pathLength}`;
        paths[i].style.strokeDashoffset = pathLength;

        setTimeout(([pathGroup, interval]) => {
            if (interval) {
                clearInterval(interval);
            }
            
            if( callBackDone ) {
                callBackDone();
            }

            pathGroup.classList.add("drawnPath");
            
            document.getElementsByClassName("cipherText")[0].textContent = "Para ver mis habilidades haz click en los puntos celestes";
        }, 3000, [pathGroup, intervalNumbers]);
    }
}

const linkCirclesToFuncs = (callbacks) => {
    const svg = document.getElementById("Cipher");
    const pathGroups = svg.getElementById("PathToUncipher").getElementsByTagName("g");

    for (let i = 0; i < pathGroups.length; i++) {
        if (callbacks.length <= i) {
            pathGroups[i].classList.add("empty");

            continue;
        }

        const circles = pathGroups[i].getElementsByTagName("circle");
        const fCircle = circles[circles.length - 1];
        
        fCircle.addEventListener("click", callbacks[i].func, false);
    }
}

class Ciphering extends Component {
    componentDidMount() {
        const svgCipher = document.getElementById("Cipher");
        const binaries = document.getElementById("Numbers").getElementsByTagName("text");

        const intervalNumbers = binariesPopping(svgCipher, binaries, ["", "0", "1"]);

        createPaths(svgCipher, intervalNumbers, this.props.callBackDone);

        if (this.props.callbacks) {
            linkCirclesToFuncs(this.props.callbacks);
        }
    }


    render() {
        return (
            <div className="CipherAnimation">
                <Cipher className="Cipher" />
                <p className="cipherText">Deciphering... <Lock className="lockIco" /></p>
            </div>
        );
    }
};

Ciphering.propTypes = {
    callbacks: PropTypes.array,
    callBackDone: PropTypes.func,
};

export default Ciphering;