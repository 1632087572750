import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function spanConditionally(type, children) {
    if( children ) {
        return <span className={type ? "type" : ""} style={{animation: `typing 0.9s steps(${children.length}, end)`}}>{children}</span>;
    } else {
        return <span className={type ? "type" : ""}>{children}</span>;
    }
}

class TerminalText extends Component {
    render() {
        const { type, children } = this.props;

        return (
            <div className="terminalText">
                {
                    spanConditionally(type, children)
                }        
            </div>
        );
    }
};

TerminalText.propTypes = {
    type: PropTypes.bool,
}

export default TerminalText;