import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navheader from './componentes/Navheader';
import Home from './componentes/Home';
import Skills from './componentes/Skills';
import Experience from './componentes/Experience';
import Misc from './componentes/Misc';
import Footerbar from './componentes/Footerbar';
import './App.scss';

function App() {
  return (
    <Router>
      <Container fluid>
        <Navheader />

        <section>
          <Switch>
            <Route path="/misc" component={Misc} />
            <Route path="/experience" component={Experience} />
            <Route path="/skills" component={Skills} />
            <Route path="/" component={Home} />
          </Switch>
        </section>

        <Footerbar />
      </Container>
    </Router>
  );
}

export default App;
