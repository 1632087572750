import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './style.scss';

const Navheader = props => {
    return (
        <Navbar className="bg-dark-light" expand="lg">
            <Navbar.Brand href="#home">German Bravo</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/skills">Skills</Nav.Link>
                    <Nav.Link href="/experience">Experience</Nav.Link>
                    <Nav.Link href="/misc">Misc</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <Nav className="ml-auto d-none d-lg-block">
                <Nav.Link href="https://github.com/fearARGENTINA"><div className="icon icon-github"></div></Nav.Link>
                <Nav.Link href="https://www.linkedin.com/in/german-bravo-174b957a/"><div className="icon icon-linkedin"></div></Nav.Link>
                <Nav.Link href="mailto:bravogermanezequiel@gmail.com"><div className="icon icon-email"></div></Nav.Link>
            </Nav>
        </Navbar>
    );
};

export default Navheader;