import React, { Component } from 'react';
import { ReactComponent as HackerSVG } from './resources/hackers.svg';
import './style.scss';

class Hacker extends Component {
    render() {
        return (
            <div className="Hacker">
                <HackerSVG className="HackerSVG" />
            </div>
        );
    }
};

export default Hacker;