import React, { Component } from 'react';
import Ciphering from './../Ciphering';
import CarouselContent from '../CarouselContent';
import ServerGraph from './SkillGraph/ServerGraph';
import { BEGINNER, INTERMEDIATE, ADVANCED, ELEMENTARIO } from './SkillGraph/constants';
import MailmanGraph from './SkillGraph/MailmanGraph';

class Skills extends Component {
    constructor() {
        super();

        this.state = {
            checkedInput: 0,
            show: false,
        }
    }

    changeShow = () => {
        this.setState({
            show: true,
        });
    }
    
    render() {
        return (
            <div>
                <Ciphering 
                    callbacks={
                        [
                            { func: () => { 
                                this.setState({ checkedInput: 0 });
                                document.querySelector("#carouselContent").scrollIntoView({ behavior: 'smooth', block: 'start'});
                            }, name: "WebDev"},
                            { func: () => { 
                                this.setState({ checkedInput: 1 })
                                document.querySelector("#carouselContent").scrollIntoView({ behavior: 'smooth', block: 'start'});
                            }, name: "SecOps"},
                            { func: () => {
                                this.setState({ checkedInput: 2 })
                                document.querySelector("#carouselContent").scrollIntoView({ behavior: 'smooth', block: 'start'});
                            },name: "..."},
                        ]
                    }
                    callBackDone={this.changeShow} />
                <CarouselContent
                    id="carouselContent"
                    show={this.state.show}
                    checkedInput={this.state.checkedInput}
                    slides={[
                        [ 
                            <ServerGraph title="Info Security I" skills={[
                                {name: "Threat Hunting", level: INTERMEDIATE }, 
                                {name: "IDS / IPS", level: ADVANCED},
                                {name: "Forensics", level: ELEMENTARIO},
                            ]} />, 
                            <ServerGraph title="Info Security II" skills={[
                                {name: "Ethical Hacking", level: ADVANCED }, 
                                {name: "Linux Skills", level: ADVANCED},
                                {name: "Web pentest", level: INTERMEDIATE}
                            ]} />
                        ],
                        [ 
                            <ServerGraph title="Programming" skills={[
                                {name: "Python", level: INTERMEDIATE }, 
                                {name: "Java", level: INTERMEDIATE},
                                {name: "Javascript", level: ADVANCED},
                            ]} />, 
                            <ServerGraph title="Programming" skills={[
                                {name: "Perl", level: BEGINNER },
                                {name: "PHP", level: INTERMEDIATE},
                                {name: "Bash scripting", level: INTERMEDIATE} 
                            ]} />
                        ],
                        [
                            <MailmanGraph loadAnimation={true} title="Webdev Skills" skills={[
                                {name: "HTML5", level: ADVANCED },
                                {name: "CSS3", level: ADVANCED},
                                {name: "Javascript", level: ADVANCED},
                                {name: "Responsive Design", level: ADVANCED},
                                {name: "SQL", level: ADVANCED},
                            ]} />,
                            <MailmanGraph loadAnimation={true} title="Webdev Frameworks" skills={[
                                {name: "React", level: ADVANCED },
                                {name: "Redux", level: INTERMEDIATE},
                                {name: "Angular", level: INTERMEDIATE},
                                {name: "Wordpress", level: ELEMENTARIO},
                                {name: "Bootstrap", level: ADVANCED},
                            ]} />,
                        ],
                        [ 8]
                    ]}/>
            </div>
        );
    }
};

export default Skills;