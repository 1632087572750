import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import Terminal from './../Terminal';
import TerminalText from './../Terminal/TerminalText';
import TerminalPrompt from './../Terminal/TerminalPrompt';
import MitM from '../Animations/MitM';
import Quarantine from '../Animations/Quarantine';
import Hacker from '../Animations/Hacker';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = props => {
    return (
        <Row>
            <Col xs={12} md={6}>
                <Terminal>
                    <TerminalText>Portfolio personal</TerminalText>
                    <TerminalPrompt type={true}>whoami</TerminalPrompt>
                    <TerminalText>
                        <p>Ethical Hacker y Webdev habil en multiples herramientas.</p>
                        <p>En el ambito web:
                        Cuento con amplia experiencia en diferentes frameworks y lenguajes de programación
                            como Bootstrap, React, Redux, Wordpress, PHP, Javascript, SQL, etc.</p>
                        <p>En el ambitos de SecInfo:
                        Tengo conocimientos y experiencia en el uso de herramientas para la auditoria de sistemas
                            y plataformas web. Threat Hunting. Bash scripting / Script Automation. OWASP. Ethical Hacking.</p>
                        <p>
                            Actualmente me encuentro realizando una pasantia en el area de Seguridad Informática de <b>TaTa S.A.</b>
                        </p>
                    </TerminalText>
                    <TerminalPrompt type={true}></TerminalPrompt>
                </Terminal>
            </Col>
            <Col xs={12} md={6}>
                <Carousel 
                    indicators={false}
                    interval={6000}
                    nextIcon={<FontAwesomeIcon icon={faArrowCircleRight} size={"2x"} color={"#333"} />}
                    prevIcon={<FontAwesomeIcon icon={faArrowCircleLeft} size={"2x"} color={"#333"} />}>
                    <Carousel.Item>
                        <Quarantine />
                    </Carousel.Item>
                    <Carousel.Item>
                        <MitM />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Hacker />
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    );
};

export default Home;